import { Inject, Injectable } from '@angular/core';
import { SiteSelectedEvent } from '@app.cobiro.com/core/events';
import { ApplicationEventsHandler, ongoingEventsOrchestrationStrategy } from '@cobiro/eda';
import { TOP_BAR_STATE_SETTER, TopBarStateSetter } from '../application/top-bar.state';

@Injectable()
export class TopBarSiteSelectedEventHandler implements ApplicationEventsHandler {
  readonly eventsClasses = [SiteSelectedEvent];
  readonly strategy = ongoingEventsOrchestrationStrategy;

  constructor(@Inject(TOP_BAR_STATE_SETTER) private readonly _topBarState: TopBarStateSetter) {}

  handle([event]: [SiteSelectedEvent]): void {
    this._topBarState.setSiteId(event.siteId);
  }
}
