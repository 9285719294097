/* eslint-disable max-lines-per-function */
/* eslint-disable complexity */
import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpEventType,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class WrongContentTypeInterceptor implements HttpInterceptor {
  constructor(private readonly router: Router) {}
  /**
   * Implements HttpInterceptor to handle 200 with wrong content-type (issue with Cloudfront).
   */
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      tap(response => {
        if (response.type === HttpEventType.Response) {
          if (response.status === 200 && response.headers.get('content-type') === 'text/html') {
            console.warn('WrongContentTypeInterceptor: content type wront');
            throw new HttpErrorResponse({
              error: "CloudFront: You don't have access to this resourses",
              headers: request.headers,
              status: 403,
              statusText: 'FORBIDDEN',
              url: request.url,
            });
          }
        }
      }),
      catchError(error => {
        console.info('response.status ', error.status, error.status === 200);
        console.info(
          'response.headers.get("content-type") ',
          error.headers.get('content-type'),
          error.headers.get('content-type') === 'text/html',
        );
        if (error?.status === 200 || error?.headers.get('content-type') === 'text/html') {
          this.router.navigate(['/connect']);
          return of(null);
        }
        return throwError(error);
      }),
    );
  }
}
