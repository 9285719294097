/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import {
  Component,
  ViewChild,
  Inject,
  OnInit,
  OnDestroy,
  ViewEncapsulation,
  Input,
  ElementRef,
  NgZone,
  AfterViewInit,
} from '@angular/core';
import { GetsLanguage, GETS_LANGUAGE } from '@app.cobiro.com/common/language';
import { ChartComponent } from 'ng-apexcharts';
import { BehaviorSubject, map, Subject, take, takeUntil, tap, filter } from 'rxjs';
import {
  CleansLMReportDateQueryPort,
  CLEANS_LM_REPORT_DATE_QUERY,
} from '../../../../application/ports/primary/cleans-lm-report-date.query-port';
import {
  GetsLabelManagerErrorMessageQueryPort,
  GETS_LABEL_MANAGER_ERROR_MESSAGE_QUERY_PORT,
} from '../../../../application/ports/primary/gets-label-manager-error-message.query-port';
import {
  GETS_LABEL_MANAGER_LOADER_MESSAGE_QUERY,
  GetsLabelManagerLoaderMessageQueryPort,
} from '../../../../application/ports/primary/gets-label-manager-loader-message.query-port';
import {
  GetsLabelManagerReportQueryPort,
  GETS_LABEL_MANAGER_REPORT_QUERY_PORT,
} from '../../../../application/ports/primary/gets-label-manager-report.query-port';
import {
  IS_LABEL_MANAGER_REPORT_LOADING_QUERY_PORT,
  IsLabelManagerReportLoadingQueryPort,
} from '../../../../application/ports/primary/is-label-manager-report-loading.query-port';
import {
  LabelManagerReportDateData,
  LabelManagerReportQuery,
} from '../../../../application/ports/primary/label-manager-report.query';
import {
  SetsCurrentLMReportQueryPort,
  SETS_CURRENT_LM_REPORT_QUERY,
} from '../../../../application/ports/primary/sets-current-lm-report.query-port';
import { ChartOptions, RESULT_CHART_OPTION } from './result-chart-option';

@Component({
  selector: 'lib-label-manager-results',
  templateUrl: './label-manager-results.component.html',
  styleUrls: ['./label-manager-results.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LabelManagerResultsComponent implements OnInit, OnDestroy, AfterViewInit {
  private _ngDestroy$ = new Subject<void>();
  @Input() isSimulation: boolean;
  @Input() defaultValues?: LabelManagerReportDateData;

  @ViewChild('chart') chart: ChartComponent;
  @ViewChild('chartContainer') chartContainer: ElementRef;
  private observer: ResizeObserver;
  public chartOptions: Partial<ChartOptions>;

  dates: string[] = ['1', '2', '3'];

  private readonly comparisonData$: BehaviorSubject<
    { label: string; value: string; percentage: string }[] | null
  > = new BehaviorSubject(null);
  private readonly lastUpdateDate$: BehaviorSubject<Date | null> = new BehaviorSubject(null);
  private readonly totalProducts$: BehaviorSubject<number | null> = new BehaviorSubject(null);
  private readonly dataReport$: BehaviorSubject<LabelManagerReportQuery | null> =
    new BehaviorSubject(null);

  readonly _comparisonData = this.comparisonData$.asObservable();
  readonly _lastUpdateDate = this.lastUpdateDate$.asObservable();
  readonly _totalProducts = this.totalProducts$.asObservable();
  readonly _dates = this.dataReport$.asObservable().pipe(
    filter(res => !!res),
    map(res => res.data.map(res => res.date)),
  );
  readonly isLoading$ = this._isLabelManagerReportLoadingQueryPort.isLoading();
  readonly loaderMessage$ = this._getsLabelManagerLoaderMessageQueryPort.getMessage();
  readonly errorMessage$ = this._getsLabelManagerErrorMessageQueryPort.getErrorMessage();

  constructor(
    @Inject(GETS_LANGUAGE)
    private readonly _languageService: GetsLanguage,
    @Inject(GETS_LABEL_MANAGER_REPORT_QUERY_PORT)
    private readonly _getsLabelManagerReportQueryPort: GetsLabelManagerReportQueryPort,
    @Inject(GETS_LABEL_MANAGER_LOADER_MESSAGE_QUERY)
    private readonly _getsLabelManagerLoaderMessageQueryPort: GetsLabelManagerLoaderMessageQueryPort,
    @Inject(IS_LABEL_MANAGER_REPORT_LOADING_QUERY_PORT)
    private readonly _isLabelManagerReportLoadingQueryPort: IsLabelManagerReportLoadingQueryPort,
    @Inject(CLEANS_LM_REPORT_DATE_QUERY)
    private readonly _cleansLMReportDateQueryPort: CleansLMReportDateQueryPort,
    @Inject(GETS_LABEL_MANAGER_ERROR_MESSAGE_QUERY_PORT)
    private readonly _getsLabelManagerErrorMessageQueryPort: GetsLabelManagerErrorMessageQueryPort,
    @Inject(SETS_CURRENT_LM_REPORT_QUERY)
    private readonly _setsCurrentLMReportQueryPort: SetsCurrentLMReportQueryPort,
    private zone: NgZone,
  ) {
    this.chartOptions = {
      ...RESULT_CHART_OPTION,
      xaxis: {
        categories: ['overIndex', 'index', 'nearIndex', 'noIndex', 'underIndex'],
        labels: {
          formatter: value => {
            return this._languageService.get(`cobiro_pro_label_manager_${value}`);
          },
        },
      },
      dataLabels: {
        formatter: (val: number, opt) => {
          const total = this.totalProducts$.getValue();
          return opt['seriesIndex'] === 0
            ? ''
            : `${(((100 - val) * total) / 100).toFixed(0)} (${(100 - val).toFixed(2)}%)`;
        },
      },
    };
  }

  loadData(): void {
    this._getsLabelManagerReportQueryPort
      .getReport(this.isSimulation)
      .pipe(take(1), takeUntil(this._ngDestroy$))
      .subscribe((res: LabelManagerReportQuery) => this.dataReport$.next(res));
  }

  getComparisonData(data: LabelManagerReportDateData[], index: number): void {
    if (data.length < 2 || index === data.length - 1) {
      this.comparisonData$.next(null);
      return;
    } else {
      const arr: { label: string; value: string; percentage: string }[] = [];

      data.at(index).dataLabels.forEach((item, i) => {
        const currentValue = data.at(index).chartSeries.at(0).data.at(i);
        const previousValue = data
          .at(index + 1)
          .chartSeries.at(0)
          .data.at(i);

        const value = currentValue - previousValue;

        const currentPercentage =
          data.at(index).totalProducts > 0
            ? (100 * currentValue) / data.at(index).totalProducts
            : 0;
        const previousPercentage =
          data.at(index + 1).totalProducts > 0
            ? (100 * previousValue) / data.at(index + 1).totalProducts
            : 0;

        const percentage = currentPercentage - previousPercentage;

        arr.splice(i, 0, {
          label: item,
          value: `${value > 0 ? '+' : ''}${value}`,
          percentage: `${percentage > 0 ? '+' : ''}${percentage.toFixed(2)}%`,
        });
      });
      this.comparisonData$.next(arr);
    }
  }
  isPositiveResult(data: { label: string; value: string }): boolean {
    switch (data.label) {
      case 'overIndex':
        return parseInt(data.value) >= 0;
      case 'index':
        return parseInt(data.value) >= 0;
      case 'nearIndex':
        return parseInt(data.value) >= 0;
      case 'noIndex':
        return parseInt(data.value) < 0;
      case 'underIndex':
        return parseInt(data.value) < 0;
    }
  }
  updateChartData(data: LabelManagerReportDateData, isLatest: boolean = true): void {
    this._setsCurrentLMReportQueryPort.setCurrentLMReport(isLatest ? data : null);
    if (data.totalProducts !== 0) {
      this.totalProducts$.next(data.totalProducts);
      this.lastUpdateDate$.next(data.date);
      this.chart.updateOptions({
        ...this.chartOptions,
        xaxis: {
          categories: data.dataLabels,
        },
        fill: {
          colors: [isLatest ? '#0063FF' : '#707070', '#E2E8F0'],
        },
      });
      this.chart.updateSeries(data.chartSeries);
    } else {
      this.lastUpdateDate$.next(data.date);
      this.totalProducts$.next(0);
      this.chart.updateSeries([]);
    }
  }

  reloadChartData(): void {
    this.dataReport$
      .asObservable()
      .pipe(
        filter(res => !!res),
        tap(res => {
          if (res.data.length > 0) {
            this.updateChartData(res.data[0]);
            this.getComparisonData(res.data, 0);
          } else {
            this.lastUpdateDate$.next(this.defaultValues ? this.defaultValues.date : null);
            this.totalProducts$.next(this.defaultValues ? this.defaultValues.totalProducts : null);
            this.chart.updateSeries(this.defaultValues ? this.defaultValues.chartSeries : []);
          }
        }),
        takeUntil(this._ngDestroy$),
      )
      .subscribe();
  }

  onDateChanged(date: Date): void {
    this.dataReport$
      .asObservable()
      .pipe(
        take(1),
        tap(res => {
          const index = res.data.findIndex(
            data => data.date.getTime() === new Date(date).getTime(),
          );
          if (index != -1) {
            this.updateChartData(res.data[index], index === 0);
            this.getComparisonData(res.data, index);
          }
        }),
      )
      .subscribe();
  }

  ngOnInit(): void {
    this.loadData();
    this.reloadChartData();
  }
  ngAfterViewInit(): void {
    this.observer = new ResizeObserver(() => {
      this.zone.run(() => {
        this.chart.render();
      });
    });
    this.observer.observe(this.chartContainer.nativeElement);
  }
  ngOnDestroy(): void {
    this._ngDestroy$.next();
    this._ngDestroy$.complete();
    this._cleansLMReportDateQueryPort.cleanLMReportDate();
    this.observer.unobserve(this.chartContainer.nativeElement);
  }
}
