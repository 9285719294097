import { Inject, Injectable } from '@angular/core';
import { FeaturesLoadedEvent } from '@app.cobiro.com/core/payments-features';
import { ApplicationEventsHandler, ongoingEventsOrchestrationStrategy } from '@cobiro/eda';
import { TOP_BAR_STATE_SETTER, TopBarStateSetter } from '../application/top-bar.state';

@Injectable()
export class TopBarFeaturesLoadedEventHandler implements ApplicationEventsHandler {
  readonly eventsClasses = [FeaturesLoadedEvent];
  readonly strategy = ongoingEventsOrchestrationStrategy;

  constructor(@Inject(TOP_BAR_STATE_SETTER) private readonly _topBarState: TopBarStateSetter) {}

  handle([event]: [FeaturesLoadedEvent]): void {
    this._topBarState.setHasUpgradeButton(event.features.has('PAY_SHOW-UPGRADE'));
  }
}
