import { Inject, Injectable } from '@angular/core';
import { UserChangedEvent } from '@app.cobiro.com/core/events';
import { ApplicationEventsHandler, ongoingEventsOrchestrationStrategy } from '@cobiro/eda';
import { TOP_BAR_STATE_SETTER, TopBarStateSetter } from '../application/top-bar.state';

@Injectable()
export class TopBarUserChangedEventHandler implements ApplicationEventsHandler {
  readonly eventsClasses = [UserChangedEvent];
  readonly strategy = ongoingEventsOrchestrationStrategy;

  constructor(@Inject(TOP_BAR_STATE_SETTER) private readonly _topBarState: TopBarStateSetter) {}

  handle([event]: [UserChangedEvent]): void {
    this._topBarState.setUser({
      id: event.user.id,
      firstName: event.user.firstName,
      lastName: event.user.lastName,
      email: event.user.email,
      avatar: event.user.avatar,
    });
  }
}
