import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { UserIsInvitedEvent } from '@app.cobiro.com/user/register';
import { GETS_DETAILS, GetsDetails, UserDetails } from '@app.cobiro.com/user/settings';
import { APPLICATION_BUS, ApplicationEvent, Dispatcher } from '@cobiro/eda';
import { BehaviorSubject, combineLatest, map, Observable, take } from 'rxjs';
import {
  LOAD_TEAMS_COMMAND,
  LoadTeamsCommandPort,
} from '../../../application/ports/primary/load-teams-command.port';
import { TeamDTO, USER_ROLE } from '../../../application/ports/secondary/team.dto';

@Injectable()
export class ShowSignUpFormGuard {
  private readonly _resolvedOnce = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly _router: Router,
    @Inject(LOAD_TEAMS_COMMAND)
    private readonly _loadTeams: LoadTeamsCommandPort,
    @Inject(APPLICATION_BUS)
    private readonly _applicationBus: Dispatcher<ApplicationEvent>,
    @Inject(GETS_DETAILS)
    private readonly _getsUserDetails: GetsDetails,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return this._hasTeam(route);
  }

  private _hasTeam(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return combineLatest([
      this._loadTeams.loadAll().pipe(take(1)),
      this._getsUserDetails.get().pipe(take(1)),
    ]).pipe(
      map(([teamList, userDetails]: [TeamDTO[], UserDetails]) => {
        const isUserDetailsProvided = userDetails.firstName !== '' && userDetails.lastName !== '';
        const isUserOwner = teamList.some(team => team.role === USER_ROLE.owner);
        this._applicationBus.dispatch(
          new UserIsInvitedEvent(!isUserOwner && !isUserDetailsProvided && teamList.length !== 0),
        );
        if (this._resolvedOnce.value || isUserDetailsProvided) {
          return true;
        }
        this._resolvedOnce.next(true);
        return isUserOwner ? true : this._router.createUrlTree(['/auth/register/create-profile']);
      }),
    );
  }
}
