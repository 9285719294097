import { Inject, Injectable } from '@angular/core';
import { ENV_NAME } from '@app.cobiro.com/cobiro-legacy';
import { WINDOW } from '@app.cobiro.com/core/utils';
import { DatadogRumDriverConfig, makeDatadogConfig } from './rum.config';

export interface DatadogRumDriver {
  init(config: DatadogRumDriverConfig): void;
}

@Injectable({
  providedIn: 'root',
})
export class RumResolver {
  constructor(
    @Inject(WINDOW) private _window: { DD_RUM: DatadogRumDriver },
    @Inject(ENV_NAME) private _envName: string,
  ) {}

  resolve(): boolean {
    if (
      ['production', 'staging'].includes(this._envName) &&
      Object.keys(this._window).includes('DD_RUM')
    ) {
      this._window.DD_RUM.init(makeDatadogConfig(this._envName));
    }
    return true;
  }
}
