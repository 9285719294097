import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { AuthenticationService } from '../legacy/authentication-service/authentication.service';
import { Token } from '../domain/refreshes-token';

@Injectable()
export class UnauthenticatedInterceptor implements HttpInterceptor {
  constructor(private router: Router, private authenticationService: AuthenticationService) {}

  private static shouldHandleRequestWithUrl(url: string): boolean {
    return (
      !url.includes('login') &&
      !url.includes('logout') &&
      !url.endsWith('refresh') &&
      !url.includes('https://api.github.com/user')
    );
  }

  /**
   * Implements HttpInterceptor to handle 401 error.
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(error => {
        console.info(error);
        if (
          (error?.status === 401 ||
            error?.errors?.[0]?.status === '401' ||
            error?.error?.[0]?.status === '401') &&
          UnauthenticatedInterceptor.shouldHandleRequestWithUrl(request.url)
        ) {
          return this.refreshToken().pipe(
            take(1),
            switchMap((newToken: string) => {
              if (!newToken) {
                this.router.navigate(['/user', 'logout']);
                return of(null);
              } else {
                return next.handle(this.addAuthenticationToken(request, newToken));
              }
            }),
          );
        }
        return throwError(error);
      }),
    );
  }

  private refreshToken(): Observable<string> {
    return this.authenticationService.refreshToken().pipe(
      catchError(_ => {
        this.router.navigate(['/user', 'logout']);
        return throwError('Could not refresh the token');
      }),
      map((refreshToken: Token) => refreshToken.accessToken),
    );
  }

  private addAuthenticationToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
    const headers = request.headers.set('Authorization', `Bearer ${token}`);
    return request.clone({ headers: headers });
  }
}
